import { Typography } from '@mui/material'

const headerStyle = {
  marginBottom: 3,
  display: 'block'
}

interface IHeaderProps {
  children: string
}

export function Header (props: IHeaderProps) {
  return (
    <Typography variant='h4' sx={{ ...headerStyle }}>
      {props.children}
    </Typography>
  )
}

interface ISaveMessage {
  visible: boolean
  children: string
}

export function SaveMessage (props: ISaveMessage) {
  return (
    <>
      {props.visible && (
        <Typography variant='body1' sx={{ backgroundColor: 'blue' }}>
          {props.children}
        </Typography>
      )}
    </>
  )
}
