import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseAuth, firebaseDb } from "../../../fbConfig";
import IGeoLocationService, { IGeoLocation } from "./IGeoLocationService";

export default class FirebaseGeoLocationService implements IGeoLocationService {
    geo: IGeoLocation

    constructor() {
        this.geo =  {
            latitude: 28.4956,
            longitude: -81.5348
        }
    }

    async get() {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")        
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            return {
                latitude: data.geolocation.latitude,
                longitude: data.geolocation.longitude,
                }
        } else {
            throw new Error("User is missing data")
        }
    }

    async update(geolocation: IGeoLocation) {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")
        await updateDoc(docRef, {
            geolocation: {
                latitude: geolocation.latitude,
                longitude: geolocation.longitude,
            }
        })

        return true
    }
}