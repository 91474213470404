import {
    AccountCircleOutlined,
    AddCircleOutlineOutlined,
    EmojiEventsOutlined,
    HouseOutlined,
    LoginOutlined,
    LogoutOutlined,
    MilitaryTechOutlined,
    MonetizationOnOutlined,
    SubjectOutlined,
    MyLocationOutlined,
    HomeOutlined,
    QrCode2Outlined,
} from '@mui/icons-material'
import {
    AppBar,
    Avatar,
    Card,
    CardContent,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Stack,
    styled,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import { isLoginPage, isNoShowLayout, isShowQRCode } from '../utils/navigation'
import { Location } from '@remix-run/router'

interface ILayout {
    children: JSX.Element
}

const contentStype = {
    bgcolor: '#f9f9f9',
    width: '100%',
    padding: 5,
}

const drawerStype = {
    width: '250px',
    top: 0,
    bottom: 0,
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
    paddingBottom: 5,
    borderRadius: '16px',
    bgcolor: 'primary.dark',
    position: 'fixed',
    overflowY: 'scroll',
    overflowX: 'hidden',
}

const RootDiv = styled('div')(
    ({ theme }) => `
        display: 'flex'
  `
)

const listItemStypeActive = {
    color: 'primary.selected',
    py: 1,
}

const listItemStypeNonActive = {
    // inherit
    color: 'neutral.main',
    py: 1,
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

export default function Layout (props: ILayout) {
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()

    const rewardsSetupItems = [
        {
            text: 'Reward',
            iconActive: <EmojiEventsOutlined color='primarySelected' />,
            iconInactive: <EmojiEventsOutlined color='neutral' />,
            path: '/reward',
        },
        {
            text: 'Geo Location',
            iconActive: <MyLocationOutlined color='primarySelected' />,
            iconInactive: <MyLocationOutlined color='neutral' />,
            path: '/geolocation',
        },
        {
            text: 'QR Code',
            iconActive: <QrCode2Outlined color='primarySelected' />,
            iconInactive: <QrCode2Outlined color='neutral' />,
            path: '/qrcode',
        },
    ]

    const accountItems = [
        {
            text: 'Profile',
            iconActive: <AccountCircleOutlined color='primarySelected' />,
            iconInactive: <AccountCircleOutlined color='neutral' />,
            path: '/profile',
        },
        {
            text: 'Billing',
            iconActive: <MonetizationOnOutlined color='primarySelected' />,
            iconInactive: <MonetizationOnOutlined color='neutral' />,
            path: '/billing',
        },
    ]

    const reportingItems = [
        {
            text: 'Stats',
            iconActive: <EmojiEventsOutlined color='primarySelected' />,
            iconInactive: <EmojiEventsOutlined color='neutral' />,
            path: '/stats',
        },
    ]

    const signoutItems = [
        {
            text: 'Signout',
            iconActive: <LogoutOutlined color='primarySelected' />,
            iconInactive: <LogoutOutlined color='neutral' />,
            path: '/login?signout=1',
        },
    ]

    const crumbTitle = (location: Location) => {
        switch (location.pathname) {
            case '/reward':
            case '/qrcode':
            case '/geolocation':
                return 'Rewards Setup'
            case '/profile':
            case '/billing':
                return 'Account'
            case '/stats':
                return 'Reports'
            default:
                return 'Dashboard'
        }
    }

    const crumbSubTitle = (location: Location) => {
        switch (location.pathname) {
            case '/reward':
                return 'Running Reward'
            case '/qrcode':
                return 'QR Code'
            case '/geolocation':
                return 'Geo Location'
            case '/profile':
                return 'Profile'
            case '/billing':
                return 'Billing'
            case '/stats':
                return 'Stats'
            default:
                return 'Dashboard'
        }
    }

    if (isNoShowLayout(location)) {
        return <Box>{props.children}</Box>
    } else {
        return (
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <Card sx={{ ...drawerStype }}>
                    <CardContent>
                        <Box>
                            <Box
                                component='img'
                                sx={{
                                    // 1.4577
                                    height: 75,
                                    width: 109,
                                    marginLeft: '54px',
                                    marginBottom: 2
                                }}
                                alt='The house from the offer.'
                                src='/logo.png'
                            />
                            <Divider variant='middle' color='#9e9e9e' />
                        </Box>
                        <SubHeader>Rewards Setup</SubHeader>
                        <MenuList menuItems={rewardsSetupItems} />
                        <SubHeader>Account</SubHeader>
                        <MenuList menuItems={accountItems} />
                        <SubHeader>Reports</SubHeader>
                        <MenuList menuItems={reportingItems} />
                        <SubHeader>Logout</SubHeader>
                        <MenuList menuItems={signoutItems} />
                    </CardContent>
                </Card>

                <Box
                    sx={{
                        minWidth: 275,
                        paddingLeft: 4,
                        paddingRight: 4,
                        paddingTop: 0,
                        paddingBottom: 10,
                        marginLeft: '300px',
                        marginTop: 2,
                        marginBottom: 10,
                        marginRight: 10,
                        width: 1,
                    }}
                >
                    <Stack
                        sx={{
                            marginLeft: 1,
                            marginBottom: 3,
                        }}
                        direction='row'
                        spacing={1}
                    >
                        <HomeOutlined />
                        <Typography variant='subtitle2'>/</Typography>
                        <Typography variant='subtitle2'>
                            {crumbTitle(location)}
                        </Typography>
                        <Typography variant='subtitle2'>/</Typography>
                        <Typography variant='subtitle2'>
                            {crumbSubTitle(location)}
                        </Typography>
                    </Stack>

                    <Card
                        sx={{
                            minWidth: 275,
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingBottom: 10,
                            paddingTop: 2,
                            width: '100%',
                            borderRadius: '16px',
                        }}
                    >
                        <CardContent>{props.children}</CardContent>
                    </Card>
                </Box>
            </Box>
        )
    }
}

interface ISubHeader {
    children: string
}

function SubHeader (props: ISubHeader) {
    return (
        <Typography
            variant='subtitle2'
            color='primary.contrastTextDark'
            sx={{
                marginTop: 3,
                marginLeft: 2,
                padding: 0,
            }}
        >
            {props.children}
        </Typography>
    )
}

function MenuList (props: IMenuList) {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <List>
            {props.menuItems.map(item => (
                <ListItem
                    button
                    key={item.text}
                    onClick={() => {
                        navigate(item.path)
                    }}
                    sx={
                        location.pathname == item.path
                            ? listItemStypeActive
                            : listItemStypeNonActive
                    }
                >
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                        {location.pathname == item.path
                            ? item.iconActive
                            : item.iconInactive}
                    </ListItemIcon>
                    <ListItemText>
                        <Typography color='inherit'>{item.text}</Typography>
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    )
}

interface IMenuList {
    menuItems: Array<IMenuItem>
}

interface IMenuItem {
    text: string
    iconActive: JSX.Element
    iconInactive: JSX.Element
    path: string
}
