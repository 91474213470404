import { createTheme } from "@mui/material";
import deepOrange from "@mui/material/colors/deepOrange";

// color help: https://m2.material.io/inline-tools/color/
const theme = createTheme({
    palette: {
        // Main theme color of purple
        primary: {
            light: '#969dc5',
            main: '#34418e',
            dark: '#121858',
            contrastText: '#fafafa',
            contrastTextDark: '#9e9e9e',
            selected: '#0277bd'
        },
        primarySelected: {
            main: '#0277bd',
        },
        // TRIADIC
        secondary: {
            light: '#cd8fbc',
            main: '#97297d',
            dark: '#521258',
            contrastText: '#fff'
        },
        neutral: {
            main: '#fafafa',
            contrastText: '#fff'
        },
    },
})

export default theme;
