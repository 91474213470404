import { Location } from '@remix-run/router'

export function isLoginPage (location: Location) {
    return (
        location.pathname === '/' ||
        location.pathname === '/login' ||
        location.pathname === '/createaccount' ||
        location.pathname === '/paymentwall'
    )
}

export function isShowQRCode (location: Location) {
    return location.pathname === '/showqrcode'
}

export function isNoShowLayout (location: Location) {
    return (
        location.pathname === '/' ||        // This is the login page
        location.pathname === '/login' ||
        location.pathname === '/createaccount' ||
        location.pathname === '/paymentwall' ||
        location.pathname === '/showqrcode'
    )
}

export function isSigningOut (location: Location) {
  return location.pathname === '/login' && location.search === '?signout=1'
}

export function isPaywall (location: Location) {
  return location.pathname === '/paymentwall'
}

