import IAuthService, { IResult } from "./IAuthService"
import { FirebaseError } from "firebase/app"
import { firebaseAuth } from "../../../fbConfig"
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import { MessageResult } from '../../../../../shire/types/Firebase/functions/MessageResult'
import { firebaseOnCall } from '../../../utils/firebaseCreateFunction'

export default class FirebaseAuthService implements IAuthService {
    ready: Promise<boolean>

    constructor () {
        this.ready = new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(
                firebaseAuth,
                user => {
                    unsubscribe()
                    resolve(true)
                },
                reject // pass up any errors attaching the listener
            )
        })
    }

    async createAccount(email: string, password: string): Promise<IResult> {
        try {
            await createUserWithEmailAndPassword(firebaseAuth, email, password)

            // Additional tables setup for the client handled by a Firebase cloud function.
            const clientSignup = firebaseOnCall<{}, MessageResult>('clientSignup')
            const result = await clientSignup({})

            return {
                success: result.success,
                error: result.message,
            }

        } catch (error) {
            let errorMessage = "Unknown error"
            if (typeof error === 'string') {
                errorMessage = error.toUpperCase()
            } else if (error instanceof FirebaseError) {
                if (error.code === "auth/email-already-in-use") {
                    errorMessage = "Email already in use"
                } else if (error.code === "auth/invalid-email") {
                    errorMessage = "Invalid email"
                } else if (error.code === "auth/operation-not-allowed") {
                    errorMessage = "Operation not allowed"
                } else if (error.code === "auth/weak-password") {
                    errorMessage = "Weak password make sure it is atleast 8 characters long"
                } else {
                    errorMessage = "Unable to create user."
                }
            }
            return {
                success: false,
                error: errorMessage
            }
        }
    }

    async auth(email: string, password: string): Promise<IResult> {
        try {
            await signInWithEmailAndPassword(firebaseAuth, email, password)
            return {
                success: true,
                error: "" 
            }

        } catch (error) {

            let errorMessage = "Unknown error"
            if (typeof error === 'string') {
                errorMessage = error.toUpperCase()

            } else if (error instanceof FirebaseError) {
                if (error.code === "auth/invalid-email") {
                    errorMessage = "Invalid email"
                } else if (error.code === "auth/wrong-password") {
                    errorMessage = "Wrong password"
                } else {
                    errorMessage = "Unable to log in user. Check email and password."
                }
            }

            return {
                success: false,
                error: errorMessage
            }
        }
    }

    async isAuth () {
        await this.ready
        return firebaseAuth.currentUser !== null
    }

    signout() {
        signOut (firebaseAuth)
    }
}
