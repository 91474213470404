import QRCode from 'react-qr-code'
import { Box, Typography } from '@mui/material'
import { qrcodeService } from '../di/diOmega'

export default function QRcode () {
    const href = qrcodeService.get()

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                p: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '0px',
            }}
        >
            <Typography variant='h5' p={2} fontWeight={700}>
                Today's QR Scan for loyalty program:
            </Typography>
            <QRCode value={href} />
        </Box>
    )
}
