import { Box, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Header } from '../components/Common'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTheme
} from 'victory'
import Loading from '../components/Loading'
import { statsService } from '../di/diOmega'
import { IValueMonth } from '../di/service/stats/IStatsService'

export default function Page () {
  const [loading, setLoading] = useState(true)
  const [visitsPerMonth, setVisitsPerMonth] = useState<IValueMonth[]>([])
  const [rewardsPerMonth, setRewardsPerMonth] = useState<IValueMonth[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const stats = await statsService.get()
      setVisitsPerMonth(stats.visitsPerMonth)
      setRewardsPerMonth(stats.rewardsPerMonth)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <Container>
      <Loading loading={loading}>
        <Header>Statisics</Header>
        <BarGraphChart
          width={400}
          height={400}
          label={'Visits per Month'}
          data={visitsPerMonth}
        />
        <BarGraphChart
          width={400}
          height={400}
          label={'Rewards per Month'}
          data={rewardsPerMonth}
        />
      </Loading>
    </Container>
  )
}

interface IBarGraphChartProps {
  label: string
  width: number
  height: number
  data: { value: number; month: string }[]
}

function BarGraphChart (props: IBarGraphChartProps) {
  return (
    <Box width={props.width} height={props.height} sx={{ marginBottom: 8 }}>
      <Typography variant='h5'>{props.label}</Typography>
      <VictoryChart theme={VictoryTheme.material} domainPadding={10}>
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickFormat={x => x}
        />
        <VictoryAxis
          dependentAxis
          // tickFormat specifies how ticks should be displayed
          tickFormat={x => `${x}`}
        />
        <VictoryBar
          style={{ data: { fill: '#c43a31' } }}
          data={props.data}
          x='month'
          y='value'
        />
      </VictoryChart>
    </Box>
  )
}
