import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { authService } from '../di/diOmega'
import { useState } from 'react'
import { isSigningOut } from '../utils/navigation'
import logoImg from '../images/logos/2-transparent.png'
import UserEntranceForm from '../components/UserEntranceForm'

export default function Page () {
    const navigate = useNavigate()
    const location = useLocation()
    const signingOut = isSigningOut(location)

    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    // Get parameter id
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    // Get the subscription paramter.
    const subId = urlParams.get('sub')
    var subscription = "1"
    if (subId == "yearly") {
        subscription = "2"
    }

    if (signingOut) {
        setMessage('Signed out.')
        authService.signout()
    }

    const handleLogin = () => {
        navigate('/login')
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (loading) {
            return
        }
        setLoading(true)

        const data = new FormData(e.currentTarget)

        const email = data.get('email')
        const password = data.get('password')
        const subscription = data.get('subscription')
        
        if (
            email == null ||
            password == null ||
            subscription == null ||
            email === '' ||
            password === '' ||
            subscription == ''
        ) {
            setErrorMessage('Your email and password are required.')
            setLoading(false)
            return
        }

        var subs = "monthly"
        if (subscription.toString() == "2") {
            subs = "yearly"
        }

        const auth = await authService.createAccount(
            email.toString(),
            password.toString(),
        )
        if (auth.success) {
            navigate('/paymentwall?sub=' + subs)
        } else {
            setErrorMessage('Unable to sign into account: ' + auth.error)
            setLoading(false)
        }
    }

    const logo = (
        <Box
            component='img'
            sx={{
                height: 75,
                width: 109,
            }}
            alt='logo'
            src={logoImg}
        />
    )

    return (
        <UserEntranceForm
            onCTAButton={handleSubmit}
            ctaButtonTitle={'Continue'}
            ctaRunning={loading}
            onLeftButton={handleLogin}
            leftButtonMsg={"Already have an account?"}
            leftButtonTitle={'Log in'}
            title={'Create your account'}
            subtitle={'Begin your journey to more repeat customers is a signup away!'}
            message={message}
            errorMessage={errorMessage}
            logo={logo}
            showSubscription={true}
            subscription={subscription}
        />
    )
}

