import './App.css'
import { createTheme, ThemeProvider } from '@mui/material'
import { deepOrange, indigo, pink } from '@mui/material/colors'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// Components //
import Layout from './components/Layout'

// Routes //
import Billing from './routes/billing'
import GeoLocation from './routes/geolocation'
import Login from './routes/login'
import CreateAccount from './routes/createAccount'
import QRCode from './routes/qrcode'
import Reward from './routes/reward'
import Stats from './routes/stats'
import ShowQRCode from './routes/showqrcode'
import Profile from './routes/profile'
import PaymentWall from './routes/paymentwall'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Auth from './components/Auth'
import { LoadScript } from '@react-google-maps/api'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import firebaseApp from './fbConfig'
import theme from './theme/theme'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const analytics = getAnalytics(firebaseApp)

const queryClient = new QueryClient()

function App () {
    return (
        <LoadScript googleMapsApiKey='AIzaSyBYxcGrcy7LpSnBfrn0O10iQ4JHw4FLndo'>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Layout>
                            <Routes>
                                <Route path='/' element={<Login />} />
                                <Route path='login' element={<Login />} />
                                <Route
                                    path='createaccount'
                                    element={<CreateAccount />}
                                />
                                <Route
                                    path='paymentwall'
                                    element={
                                        <Auth>
                                            <PaymentWall />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='billing'
                                    element={
                                        <Auth>
                                            <Billing />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='geolocation'
                                    element={
                                        <Auth>
                                            <GeoLocation />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='qrcode'
                                    element={
                                        <Auth>
                                            <QRCode />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='reward'
                                    element={
                                        <Auth>
                                            <Reward />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='stats'
                                    element={
                                        <Auth>
                                            <Stats />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='showqrcode'
                                    element={
                                        <Auth>
                                            <ShowQRCode />
                                        </Auth>
                                    }
                                />
                                <Route
                                    path='profile'
                                    element={
                                        <Auth>
                                            <Profile />
                                        </Auth>
                                    }
                                />
                            </Routes>
                        </Layout>
                    </BrowserRouter>
                </ThemeProvider>
            </QueryClientProvider>
        </LoadScript>
    )
}

export default App
