import { Box } from '@mui/material'
import { PacmanLoader } from 'react-spinners'

interface ILayoutProps {
  children: JSX.Element | JSX.Element[]
  loading: boolean
}

export default function Loading (props: ILayoutProps) {
  return (
    <>
      {props.loading && (
        <Box
          sx={{
            width: '100%',
            height: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <PacmanLoader color='#36d7b7' />
        </Box>
      )}
      {!props.loading && props.children}
    </>
  )
}
