import {
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { act } from 'react-dom/test-utils'
import { Header } from '../components/Common'
import Loading from '../components/Loading'
import { billingService } from '../di/diOmega'
import firebaseApp from '../fbConfig'

const cancelStyle = {
    position: 'absolute',
    right: 0,
    marginRight: 4,
}

const stackStyle = {
    marginTop: 10,
}

const buttonStyle = {
    width: '400px',
    height: '200px',
    fontSize: 24,
}

const activeStyle = {
    height: '50px',
}

export default function Page () {
	const [loading, setLoading] = useState(false)

    async function onClickButton () {
		setLoading(true)
        const url = await billingService.billingDashboardUrl()
        window.location.assign(url)
    }

    return (
        <Loading loading={loading}>
            <Container>
                <Header>Billing</Header>
                <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={10}
                    sx={{ ...stackStyle }}
                >
                    <Box
                        sx={{ marginTop: 2 }}
                        justifyContent='center'
                        display='flex'
                    >
                        <Button
                            variant='contained'
                            sx={{ fontSize: 20 }}
                            onClick={() => {
                                onClickButton()
                            }}
                        >
                            Stripe Billing Dashboard
                        </Button>
                    </Box>
                </Stack>
            </Container>
        </Loading>
    )
}
