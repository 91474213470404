import {
    initializeAuthService,
    initializeBillingService,
    initializeGeoLocationService,
    initializeProfileService,
    initializeQRCodeService,
    initializeRewardService,
    initializeStatsService,
} from './setup/firebaseOmega'

export const authService = initializeAuthService()
export const rewardService = initializeRewardService()
export const profileService = initializeProfileService()
export const statsService = initializeStatsService()
export const qrcodeService = initializeQRCodeService()
export const geolocationService = initializeGeoLocationService()
export const billingService = initializeBillingService()
