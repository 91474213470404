import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseAuth, firebaseDb } from "../../../fbConfig";
import IRewardService, { IReward } from "./IRewardService";

export default class FirebaseRewardService implements IRewardService {

    async update(reward: IReward) {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")
        await updateDoc(docRef, {
            templateReward: {
                visits: reward.visits,
                reward: reward.reward
            }
        })

        return true
    }

    async get() {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        try {
            const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")        
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data()
                return {
                    id: 1,
                    visits: data.templateReward.visits,
                    reward: data.templateReward.reward
                }
            } else {
                throw new Error("User is missing data")
            }
        } catch (ex) {
            console.log("ERROR: ex:", ex)
            throw new Error("User is missing data")
        }
    }
}
