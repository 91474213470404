import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { authService, billingService } from '../di/diOmega'
import { isLoginPage, isPaywall } from '../utils/navigation'

interface IAuthProps {
    children: JSX.Element
}

export default function Auth (props: IAuthProps) {
    const location = useLocation()
    const [navigateTo, setNavigateTo] = useState('/login')
    const [loading, setLoading] = useState(true)
    const [canAccessRoute, setCanAccessRoute] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const isAuthed = await authService.isAuth()

			// User is not logged in so go to login screen.
            if (!isAuthed) {
                setLoading(false)
                return
            }

			// If user isn't an active subscription then go to paymentwall.
			if (!isPaywall(location)) {
				const active = await billingService.isActiveSubscription()
				if (!active) {
					setLoading(false)
					setNavigateTo('/paymentwall')
					return
				}	
			}

            setCanAccessRoute(true)
            setLoading(false)
        }
        fetchData()
    }, [])

    return loading ? (
        <></>
    ) : canAccessRoute ? (
        props.children
    ) : (
        <Navigate to={navigateTo} replace />
    )
}
