import {
  Box,
  Button,
  Container,
  FormLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Header, SaveMessage } from '../components/Common'

import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined'
import SendIcon from '@mui/icons-material/Send'

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { PacmanLoader } from 'react-spinners'
import Loading from '../components/Loading'
import { rewardService } from '../di/diOmega'
import { Save } from '@mui/icons-material'

export default function Page () {
  const [visitCount, setVisitCount] = useState(7)
  const [rewardText, setRewardText] = useState('Single item 25% off')
  const [loading, setLoading] = useState(true)
  const [rewardId, setRewardId] = useState(0)
  const [saved, setSaved] = useState(false)

  const save = async () => {
    const reward = {
      id: rewardId,
      visits: visitCount,
      reward: rewardText
    }
    await rewardService.update(reward)
    setSaved(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const reward = await rewardService.get()
      setVisitCount(reward.visits)
      setRewardText(reward.reward)
      setRewardId(reward.id)
      setLoading(false)
    }
    fetchData()
  }, [])

  // 1.) Show a loading...
  // 2.) Load data in
  // 3.) put the reward to the service

  return (
    <Container>
      <SaveMessage visible={saved}>Reward has been updated.</SaveMessage>
      <Loading loading={loading}>
        <Header>Running Reward</Header>
        <RewardBar visitCount={visitCount} />
        <Form
          visitCount={visitCount}
          setVisitCount={setVisitCount}
          rewardText={rewardText}
          setRewardText={setRewardText}
          save={save}
        />
      </Loading>
    </Container>
  )
}

const fieldStype = {
  marginTop: 3,
  marginBottom: 3,
  display: 'block'
}

interface IFormProps {
  visitCount: number
  setVisitCount: Dispatch<SetStateAction<number>>
  rewardText: string
  setRewardText: Dispatch<SetStateAction<string>>
  save: () => Promise<void>
}

function Form (props: IFormProps) {
  const [visitCountError, setVisitCountError] = useState(false)

  const [rewardTextError, setRewardTextError] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    props.save()
    e.preventDefault()
  }

  function setVisitCount (value: string) {
    let integer = parseInt(value)

    if (value === '') {
      props.setVisitCount(0)
    } else if (Number.isNaN(integer)) {
      setVisitCountError(true)
    } else {
      props.setVisitCount(integer)
    }
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit}>
      <TextField
        onChange={e => setVisitCount(e.target.value)}
        label='Visits Required (1-14):'
        sx={{ ...fieldStype }}
        fullWidth
        error={visitCountError}
        value={props.visitCount}
        required
      />
      <TextField
        onChange={e => props.setRewardText(e.target.value)}
        label='Reward:'
        sx={{ ...fieldStype }}
        fullWidth
        error={rewardTextError}
        value={props.rewardText}
        required
      />
      <Button
        type='submit'
        color='primary'
        variant='outlined'
        endIcon={<SendIcon />}
      >
        Save
      </Button>
    </form>
  )
}

interface IRewardBarProps {
  visitCount: Number
}

function RewardBar (props: IRewardBarProps) {
  console.log('props.visitCount: ', props.visitCount)

  let count = props.visitCount
  if (count < 1) {
    count = 1
  } else if (count > 14) {
    count = 14
  }

  return (
    <Stack direction='row'>
      {[...Array(count)].map((e, i) => (
        <VisitTick />
      ))}
    </Stack>
  )
}

const visitTickStyle = {
  height: '50px',
  width: '50px'
}

function VisitTick () {
  return (
    <Box>
      <StarBorderOutlined sx={{ ...visitTickStyle }} />
    </Box>
  )
}
