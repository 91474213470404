import { doc, getDoc } from "firebase/firestore";
import { firebaseAuth, firebaseDb } from "../../../fbConfig";
import IStatsService from "./IStatsService";

export default class FirebaseStatsService implements IStatsService {
    async get() {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            return {
                visitsPerMonth: data.stats.visitsPerMonth,
                rewardsPerMonth: data.stats.rewardsPerMonth
                }
        } else {
            throw new Error("User is missing data")
        }
    }
}
