import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  MarkerF,
} from '@react-google-maps/api'
import { Container, Typography } from '@mui/material'
import { Header } from '../components/Common'
import { IGeoLocation } from '../di/service/geolocation/IGeoLocationService'
import { geolocationService } from '../di/diOmega'
import Loading from '../components/Loading'

const containerStyle = {
  width: '600px',
  height: '600px'
}

const textStyle = {
  marginBottom: 3,
  display: 'block'
}

function Page () {
  const [geolocation, setGeoLocation] = useState<IGeoLocation>({
    latitude: 28.4956,
    longitude: -81.5348
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const geo = await geolocationService.get()
      setGeoLocation(geo)
      setLoading(false)
    }
    fetchData()
  }, [])

  const save = async (latitude: number, longitude: number) => {
    const geo = {
      latitude: latitude,
      longitude: longitude
    }
    await geolocationService.update(geo)
  }

  const onclick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng !== null) {
      setGeoLocation({
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng()
      })
      save(event.latLng.lat(), event.latLng.lng())
    }
  }

  return (
    <Container>
      <Header>Geo Location of Your Business</Header>
      <Typography variant='body1' sx={{ ...textStyle }}>
        Find your exact location of your business and click on the center part
        of the building. This will place a marker there. Zoom in as far as possible.
      </Typography>
      <Loading loading={loading}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: geolocation.latitude,
            lng: geolocation.longitude
          }}
          zoom={13}
          onClick={onclick}
        >
          <MarkerF
            position={{
              lat: geolocation.latitude,
              lng: geolocation.longitude
            }}
          />
        </GoogleMap>
      </Loading>
    </Container>
  )
}
export default React.memo(Page)
