import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    createTheme,
    CssBaseline,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'

import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import lottieJson from '../lottie/97443-loading-gray.json'
import { useState } from 'react'

const theme = createTheme()

interface IUserEntranceForm {
    onCTAButton: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
    ctaButtonTitle: string
    ctaRunning: boolean

    onLeftButton: () => void
    leftButtonMsg: string
    leftButtonTitle: string

    onRightButton?: () => void
    rightButtonTitle?: string

    title: string
    subtitle?: string

    message: string
    errorMessage: string
    logo: JSX.Element

    showSubscription: boolean
    subscription: string
}

export default function UserEntranceForm (props: IUserEntranceForm) {
    const [subscription, setSubscription] = useState(
        props.subscription
    )

    var ctaButtonBgColor = ''
    if (props.ctaRunning) {
        ctaButtonBgColor = 'grey'
    }

    const handleSobscription = (event: SelectChangeEvent) => {
        setSubscription(event.target.value as string)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1.5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {props.logo}
                    <Typography
                        component='h1'
                        variant='h4'
                        marginTop={{ xs: 4, sm: 10, md: 20, lg: 24 }}
                        sx={{ fontWeight: 'bold' }}
                    >
                        {props.title}
                    </Typography>
                    {props.subtitle !== undefined && props.subtitle != '' && (
                        <Typography variant='body1' sx={{ mt: 1, mb: 2 }}>
                            {props.subtitle}
                        </Typography>
                    )}
                    {props.message !== '' && (
                        <Typography
                            variant='body1'
                            sx={{ backgroundColor: 'blue' }}
                        >
                            {props.message}
                        </Typography>
                    )}
                    {props.errorMessage !== '' && (
                        <Typography
                            variant='body1'
                            sx={{ backgroundColor: 'red' }}
                        >
                            {props.errorMessage}
                        </Typography>
                    )}
                    <Box
                        component='form'
                        onSubmit={props.onCTAButton}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            name='email'
                            label='Email Address'
                            autoComplete='email'
                            autoFocus
                        />
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            autoComplete='current-password'
                        />
                        {props.showSubscription == true && (
                            <FormControl margin='normal' fullWidth>
                                <InputLabel id='subscriptionLabelId'>
                                    Subscription
                                </InputLabel>
                                <Select
                                    labelId='subscriptionLabelId'
                                    id='subscription'
                                    name='subscription'
                                    value={subscription}
                                    label='Subscription'
                                    onChange={handleSobscription}
                                >
                                    <MenuItem value={1}>
                                        Monthly
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Yearly
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            sx={{
                                mt: 3,
                                mb: 2,
                                pt: 2,
                                pb: 2,
                                bgcolor: ctaButtonBgColor,
                            }}
                        >
                            {props.ctaRunning === false ? (
                                props.ctaButtonTitle
                            ) : (
                                <Lottie
                                    loop
                                    animationData={lottieJson}
                                    play
                                    style={{
                                        height: '20px',
                                    }}
                                />
                            )}
                        </Button>
                        {props.rightButtonTitle !== undefined &&
                            props.rightButtonTitle !== '' && (
                                <Grid
                                    container
                                    alignItems='center'
                                    justifyContent='center'
                                >
                                    <Grid item></Grid>
                                    <Grid item>
                                        <Button onClick={props.onRightButton}>
                                            {props.rightButtonTitle}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        <Grid
                            container
                            spacing={1}
                            alignItems='center'
                            justifyContent='center'
                            sx={{ mt: 1.5, mb: 1.5 }}
                        >
                            <Grid item xs={5}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography align='center'>OR</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Grid item>
                                <Typography>{props.leftButtonMsg}</Typography>
                            </Grid>
                            <Grid item>
                                <Button onClick={props.onLeftButton}>
                                    {props.leftButtonTitle}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}
