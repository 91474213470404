import QRCode from 'react-qr-code'
import { Box, Container, Typography, Link, Button } from '@mui/material'
import { Header } from '../components/Common'
import { useNavigate } from 'react-router-dom'
import { QrCode2Outlined } from '@mui/icons-material'

function getRandomInt (min: number, max: number) {
    return min + Math.floor(Math.random() * (max - min))
}

export default function Page () {
    const navigate = useNavigate()
    const random = getRandomInt(1000000, 9999999)
    return (
        <Container>
            <Header>QR Code</Header>

            <Typography variant='body1'>
                Click on the following link for the QR Code which you would
                print out and have the clients of your business scan with their
                phone to increment their visit count.
            </Typography>

            <Box sx={{ marginTop: 6 }} justifyContent='center' display='flex'>
                <Button
                    variant='contained'
                    sx={{ fontSize: 20 }}
                    onClick={() => {
                        navigate('/showqrcode')
                    }}
                >
                    <QrCode2Outlined sx={{marginRight:1}} color='neutral' />
                    QR Code
                </Button>
            </Box>
        </Container>
    )
}
