import { Container } from '@mui/material'
import { useEffect } from 'react'
import { Header } from '../components/Common'
import { addDoc, collection, onSnapshot } from 'firebase/firestore'
import { firebaseAuth, firebaseDb } from '../fbConfig'

const fieldStype = {
    marginTop: 3,
    marginBottom: 3,
    display: 'block',
}

export default function Page () {

    // Get parameter id
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    
    // Get the subscription paramter convert to 
    const subId = urlParams.get('sub')    
    var stripe_price = process.env.REACT_APP_STRIPE_MONTHY_PRICE_ID
    if (subId == "yearly") {
        stripe_price = process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID
    }

    useEffect(() => {
        const fetchData = async () => {
			const docRef = await addDoc (
				collection(firebaseDb, 'customers', firebaseAuth.currentUser!.uid, 'checkout_sessions'), {
                    price: stripe_price,
                    success_url: window.location.origin + "/reward",
                    cancel_url: window.location.origin,
				}
			)

			onSnapshot(docRef, (snap) => {
                const data = snap.data()
				if (data == null) {
                    alert(`Unable to send you to payment gateway. Please try again.`)
				}

                if (data!.error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`Unable to send you to payment gateway. Please try again: ${data!.error.message}`)
                }
                if (data!.url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(data!.url)
                }
			})
        }
        fetchData()
    }, [])

    return (
        <Container>
            <Header>Redirecting to payment...</Header>
        </Container>
    )
}
