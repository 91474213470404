import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { firebaseAuth, firebaseDb } from '../../../fbConfig'
import IBillingService from './IBillingService'

export default class FirebaseBillingService implements IBillingService {
    async isActiveSubscription (): Promise<boolean> {
        if (firebaseAuth.currentUser == null) {
            return false
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")        
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            return false
        }
        const data = docSnap.data()
        if (data.demo !== undefined && data.demo === "Y") {
            return true
        }

        const q = query(
            collection(
                firebaseDb,
                'customers',
                firebaseAuth.currentUser.uid,
                'subscriptions'
            ),
            where('status', 'in', ['trialing', 'active'])
        )
        const querySnapshot = await getDocs(q)
        var active = false
        querySnapshot.forEach(doc => {
            active = true
        })

        return active
    }

    async billingDashboardUrl (): Promise<string> {
        const functions = getFunctions()
        const callable = httpsCallable(
            functions,
            'ext-firestore-stripe-payments-createPortalLink'
        )
        const data = await callable({
            returnUrl: window.location.origin + '/billing',
            locale: 'auto', // Optional, defaults to "auto"
        })

        return (data.data as any).url
    }
}
