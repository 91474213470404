import {
  Box,
  Button,
  Container,
  FormLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Header, SaveMessage } from '../components/Common'

import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined'
import SendIcon from '@mui/icons-material/Send'

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { profileService } from '../di/diOmega'
import { Save } from '@mui/icons-material'

export default function Page () {
  const [loading, setLoading] = useState(true)
  const [saved, setSaved] = useState(false)

  const [businessName, setBusinessName] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [state, setState] = useState('')

  const save = async () => {
    const profile = {
      businessName,
      addressLine1,
      addressLine2,
      city,
      zipCode,
      state
    }
    await profileService.update(profile)
    setSaved(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const profile = await profileService.get()
      setBusinessName(profile.businessName)
      setAddressLine1(profile.addressLine1)
      setAddressLine2(profile.addressLine2)
      setCity(profile.city)
      setZipCode(profile.zipCode)
      setState(profile.state)
      setLoading(false)
    }
    fetchData()
  }, [])

  const formProps = {
    businessName,
    addressLine1,
    addressLine2,
    city,
    zipCode,
    state,
    setBusinessName,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setZipCode,
    setState,
    save
  }

  return (
    <Container>
      <SaveMessage visible={saved}>Profile has been updated.</SaveMessage>
      <Loading loading={loading}>
        <Header>Profile</Header>
        <Form {...formProps}></Form>
      </Loading>
    </Container>
  )
}

interface IFormProps {
  businessName: string
  addressLine1: string
  addressLine2: string
  city: string
  zipCode: string
  state: string

  setBusinessName: Dispatch<SetStateAction<string>>
  setAddressLine1: Dispatch<SetStateAction<string>>
  setAddressLine2: Dispatch<SetStateAction<string>>
  setCity: Dispatch<SetStateAction<string>>
  setZipCode: Dispatch<SetStateAction<string>>
  setState: Dispatch<SetStateAction<string>>

  save: () => Promise<void>
}

function Form (props: IFormProps) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.save()
  }

  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit}>
      <MyTextField
        label='Business Name'
        value={props.businessName}
        setValue={props.setBusinessName}
      />
      <MyTextField
        label='Address Line 1'
        value={props.addressLine1}
        setValue={props.setAddressLine1}
      />
      <MyTextField
        label='Address Line 2'
        value={props.addressLine2}
        setValue={props.setAddressLine2}
      />
      <MyTextField label='City' value={props.city} setValue={props.setCity} />
      <MyTextField
        label='Zip Code'
        value={props.zipCode}
        setValue={props.setZipCode}
      />
      <MyTextField
        label='State'
        value={props.state}
        setValue={props.setState}
      />
      <Button
        type='submit'
        color='primary'
        variant='outlined'
        endIcon={<SendIcon />}
      >
        Save
      </Button>
    </form>
  )
}

interface IMyTextFieldProps {
  label: string
  value: string
  setValue: Dispatch<SetStateAction<string>>
}

const fieldStype = {
  marginTop: 3,
  marginBottom: 3,
  display: 'block'
}

function MyTextField (props: IMyTextFieldProps) {
  return (
    <TextField
      onChange={e => props.setValue(e.target.value)}
      label={props.label}
      sx={{ ...fieldStype }}
      fullWidth
      value={props.value}
      required
    />
  )
}
