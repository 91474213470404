import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { authService } from '../di/diOmega'
import { useEffect, useState } from 'react'
import { isSigningOut } from '../utils/navigation'
import logoImg from '../images/logos/2-transparent.png'
import UserEntranceForm from '../components/UserEntranceForm'

export default function Page () {
    const navigate = useNavigate()
    const location = useLocation()
    const signingOut = isSigningOut(location)

    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (signingOut) {
            setMessage('Signed out.')
            authService.signout()
            return
        }
    }, [])

    const handleCreateAccount = () => {
        navigate('/createaccount')
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (loading) {
            return
        }
        setLoading(true)

        const data = new FormData(e.currentTarget)

        const email = data.get('email')
        const password = data.get('password')

        if (
            email == null ||
            password == null ||
            email === '' ||
            password === ''
        ) {
            setErrorMessage('Your email and password are required.')
            setLoading(false)
            return
        }

        const auth = await authService.auth(
            email.toString(),
            password.toString()
        )
        if (auth.success) {
            navigate('/reward')
        } else {
            setErrorMessage('Unable to sign into account: ' + auth.error)
            setLoading(false)
        }
    }

    const logo = (
        <Box
            component='img'
            sx={{
                height: 75,
                width: 109,
            }}
            alt='logo'
            src={logoImg}
        />
    )

    return (
        <UserEntranceForm
            onCTAButton={handleSubmit}
            ctaButtonTitle={'Continue'}
            ctaRunning={loading}
            onLeftButton={handleCreateAccount}
            leftButtonMsg={"Don't have an account?"}
            leftButtonTitle={'Sign up'}
            onRightButton={function (): void {
                throw new Error('Function not implemented.')
            }}
            rightButtonTitle={'Forget Password?'}
            title={'Welcome Back'}
            message={message}
            errorMessage={errorMessage}
            logo={logo}
            showSubscription={false}
            subscription={'1'}
        />
    )
}
