import LocalDBApiService from "../service/api/LocalDBApiService";
import FirebaseAuthService from "../service/auth/FirebaseAuthService";
import FirebaseBillingService from "../service/billing/FirebaseBillingService";
import FirebaseGeoLocationService from "../service/geolocation/FirebaseGeoLocationService";
import FirebaseProfileService from "../service/profile/FirebaseProfileService";
import FirebaseQRCodeService from "../service/qrcode/FirebaseQRCodeService";
import FirebaseRewardService from "../service/reward/FirebaseRewardService";
import FirebaseStatsService from "../service/stats/FirebaseStatsService";

export function initializeApiService() {
    return new LocalDBApiService();
}

export function initializeAuthService() {
    return new FirebaseAuthService();
}

export function initializeRewardService() {
    return new FirebaseRewardService();
}

export function initializeProfileService() {
    return new FirebaseProfileService();
}

export function initializeStatsService() {
    return new FirebaseStatsService();
}

export function initializeQRCodeService() {
    return new FirebaseQRCodeService();
}

export function initializeGeoLocationService() {
    return new FirebaseGeoLocationService();
}

export function initializeBillingService() {
    return new FirebaseBillingService();
}
