import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseAuth, firebaseDb } from "../../../fbConfig";
import IProfileService, { IProfile } from "./IProfileService";

export default class FirebaseProfileService implements IProfileService {

    async update(profile: IProfile) {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")
        await updateDoc(docRef, {
            profile: {
                businessName: profile.businessName,
                addressLine1: profile.addressLine1,
                addressLine2: profile.addressLine2,
                city: profile.city,
                zipCode: profile.zipCode,
                state: profile.state
            }
        })

        return true
    }

    async get() {
        if (firebaseAuth.currentUser == null) {
            throw new Error("User is not signed in")
        }

        const docRef = doc(firebaseDb, "client_users", firebaseAuth.currentUser.uid, "locations", "default")        
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data()
            return {
                businessName: data.profile.businessName,
                addressLine1: data.profile.addressLine1,
                addressLine2: data.profile.addressLine2,
                city: data.profile.city,
                zipCode: data.profile.zipCode,
                state: data.profile.state
                }
        } else {
            throw new Error("User is missing data")
        }
    }
}
